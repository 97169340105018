import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { useInView } from "react-intersection-observer";
import "../assets/styles/style.css";

const PricingCard = ({ title, price, startingAt, description, delay, popular }) => {
    const { ref, inView } = useInView({ triggerOnce: true });

    return (
        <div 
            ref={ref} 
            className={`pricing-card p-4 rounded h-100 d-flex flex-column ${inView ? 'animate__animated animate__fadeInUp' : ''}`} 
            style={{ animationDelay: `${delay}s` }}
        >
            {popular && (
                <div className="most-populor py-1 px-5 rounded-pill mb-3 align-self-center ">
                    <span>Most popular</span>
                </div>
            )}
            <h2>{title}</h2>
            <p className="font-weight-bold my-3 amount">{price} <span className="small">{startingAt}</span></p>
            <p className="price-text flex-grow-1">{description}</p>
            <div className="mt-auto d-flex justify-content-center">
                <ScrollLink to="contact" smooth={true} duration={500} style={{ cursor: "pointer" }} className="btn btn-outline-light w-100 pricing-btn">
                    Start your Journey
                </ScrollLink>
            </div>
        </div>
    );
};

export default PricingCard;
