import React from "react";
import PricingCard from "./PricingCard";
import SEO from "./SEO";

const Pricing = () => {
    const pricingOptions = [
        {
            title: "Normal Informative Website (Landing Page)",
            price: "$499 /",
            startingAt : 'Starting at',
            description: "Turn your business online with a custom-designed informative website. Starting from $499, we'll create a high-quality landing page tailored to your business. Depending on your needs, additional features and services can be included, with packages ranging up to $999",
            delay: 0.2,
        },
        {
            title: "Product Development",
            price: "$999 /",
            startingAt : 'Starting at',
            description: "The price of the MVP depends on the complexity of your project. Prices range from $999 to $3,000, with guaranteed delivery within the timeline.",
            delay: 0.4,
            popular: true,
        },
        {
            title: "Website Recreation",
            price: "$999 /",
            startingAt : 'Starting at',
            description: "Do you already have product and want to add more capabilities and scale it? Let's have a call to discuss more",
            delay: 0.6,
        },
        {
            title: "SEO",
            price: "",
            startingAt : 'Depends on project complexity and time',
            description: "The price of SEO depends on project complexity and time tacking",
            delay: 0.8,
        },
    ];

    return (
        <div className="container">
            <SEO title="Pricing Options" description="Explore our pricing plans for websites, product development, and SEO services." />
            <h1 className="h3 text-center mb-5 text-primary animate__animated animate__fadeInUp">Pricing Options</h1>
            <div className="container">
                <div className="row g-4">
                    {pricingOptions.map((option, index) => (
                        <div className="col-lg-3 col-md-6 mb-5" key={index}>
                            <PricingCard {...option} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Pricing;
